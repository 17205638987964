import { Loading } from "element-ui";
// 验证码组件
import SendCode from "./components/send-code/index.vue";
// 用户信息
import consumer_user_signUser from "@/lib/data-service/haolv-default/consumer_user_signUser";
//获取12306登录账号信息
import consumer_trains_account_myAccount from "@/lib/data-service/haolv-default/consumer_trains_account_myAccount";
// 验证手机号码和验证码
import consumer_sys_sms_vcodeVerify from "@/lib/data-service/haolv-default/consumer_sys_sms_vcodeVerify";
// 修改成新号码
import consumer_user_updatePhone from "@/lib/data-service/haolv-default/consumer_user_updatePhone";
import consumer_user_updateEmail from "@/lib/data-service/haolv-default/consumer_user_updateEmail";// 绑定邮箱
// 修改或新增证件
import consumer_user_updateUser from "@/lib/data-service/haolv-default/consumer_user_updateUser";
import moment from "moment";
// 修改或新增证件
// import consumer_certificate_deleteCerById from "@/lib/data-service/haolv-default/consumer_certificate_deleteCerById";
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
export default {
  components: {
    SendCode,
  },
  data() {
    const validateCertificate = (rule, value, callback) => {
      if (value) {
        if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的身份证号码'));
        }
      } else {
        callback(new Error('请输入身份证号码'));
      }
    };

    return {
      loadingInstance: null,
      loading: false,
      detailLoading: false,
      cardLoading: false,
      detail: {
        tusCompany: {},
        // tusDept: {},
      },

      oldShow: true,
      phoneVisible: false,
      errCodeMsg: '',

      emailVisible: false,
      emailForm: {
        oldEmail: '',
        newEmail: '',
      },
      placeholderEmailTxt: '',
      errMailMsg: '',
      phoneDialogTitle: '',
      // 旧手机表单
      oldForm: {
        phone: "",
        vcode: "",
      },
      oldRules: {
        vcode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, message: "请输入正确格式的验证码", trigger: "blur" },
        ],
      },
      // 新手机表单
      newForm: {
        phone: "",
        vcode: "",
      },
      newRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        vcode: [
          {required: true, message: "请输入验证码", trigger: "blur"},
          {min: 6, message: "请输入正确格式的验证码", trigger: "blur"},
        ],
      },

      certificateVisible: false,
      certificateForm: {
        // 用户id
        // userId: '',
        // 生日
        birthday: "",
        // 中文名
        realName: "",
        // 姓
        familyName: '',
        // 名
        givenName: '',
        // 英文名
        englishName: "",
        // 英文姓
        englishSurnames: "",
        // 性别
        staffSex: "1",
        // 证件集合
        certificates: [],
        // 国籍
        countryCode: '',
      },
      certificateRules: {
        realName: [
          { required: true, message: "请输入中文名", trigger: "blur" },
          { pattern: /[\u4e00-\u9fa5]/g, message: "请输入正确的中文名", trigger: "blur" },
        ],
        familyName: [
          { required: true, message: "请输入姓", trigger: "blur" },
        ],
        givenName: [
          { required: true, message: "请输入名", trigger: "blur" },
        ],
        englishName: [
          { required: true, message: "请输入英文姓", trigger: "blur" },
        ],

        englishSurnames: [
          { required: true, message: "请输入英文名", trigger: "blur" },
        ],

        certificateType: [
          { required: true, message: "请选择证件类型", trigger: "blur" },
        ],
        certificateValue: [
          { required: true, message: "请输入证件编号", trigger: "blur" },
        ],
        identityValue: [
          { required: true, validator: validateCertificate, trigger: 'blur' },
        ],
      },

      sexList: [
        { label: 1, text: "男" },
        { label: 2, text: "女" },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      certificateTypes: [
        /*{ value: 1, label: "身份证" },
        { value: 2, label: "护照" },
        { value: 4, label: "回乡证" },
        { value: 5, label: "台胞证" },
        { value: 6, label: "军官证" },
        { value: 7, label: "香港身份证" },
        { value: 11, label: "澳门身份证" },
        { value: 10, label: "其它" },*/
      ],

      defaultIndex: '',
      trainAccount: {},

      countryList: [],
    };
  },
  computed: {
    showAddCertificateBtn() {
      const certificateForm = this.certificateForm;
      const certificateTypes = this.certificateTypes;
      if (certificateForm.certificates) {
        return certificateForm.certificates.length < certificateTypes.length
      } else {
        return false
      }
    },
  },
  filters: {
    format_cardType(val) {
      switch (Number(val)) {
        case 1:
          return "身份证";
          break;
        case 2:
          return "护照";
          break;
        case 3:
          return "港澳通行证";
          break;
        case 4:
          return "回乡证";
          break;
        case 5:
          return "台胞证";
          break;
        case 6:
          return "军官证";
          break;
        case 7:
          return "香港身份证";
          break;
        case 8:
          return "台湾通行证";
          break;
        case 9:
          return "户口本";
          break;
        case 10:
          return "其它";
          break;
        case 11:
          return "澳门身份证";
          break;
        case 12:
          return "港澳台居民居住证";
          break;
      }
    },
    format_sex(val) {
      switch (Number(val)) {
        case 1:
          return "男";
          break;
        case 2:
          return "女";
          break;
        default:
          return "--";
      }
    },
    emailTitleFilter(email) {
      return email ? '更换邮箱' : '绑定邮箱'
    }
  },
  methods: {
    formatCardType(val) {
      let text = '';
      console.log(val, this.certificateTypes);
      this.certificateTypes.forEach(value => {
        if (Number(val) === value.value) {
          text = value.label;
        }
      })
      return text;

    },
    formatCardId(val, type) {
      if (val != '') {
        if (type == 1) {
          //身份证
          // let text = value.slice(0, 4) + '********' + value.slice(15);
          let text=val.replace(/(\d{4})(\d+)(\d{3})/,function(x,y,z,p){
            var i="";
            while(i.length<z.length){i+="*"}
            return y+i+p
          })
          return text
        } else {
          let text=val.replace(/(\d{2})(\d+)(\d{2})/,function(x,y,z,p){
            var i="";
            while(i.length<z.length){i+="*"}
            return y+i+p
          })
          return text
        }
      } else {
        return ''
      }
    },
    init() {
      //this.loading = true;
      this.getUserInfo();
      this.getTrainAccountInfo();
      //this.loading = false;
    },
    getCertificateType() {
      consumer_admin_flight_generate_certificateType().then(res => {
        let certificateTypes = res.datas;
        certificateTypes.forEach(value => {
          value.value = value.code;
          value.label = value.msg;
        })
        this.certificateTypes = certificateTypes
      })
    },

    //获取用户信息
    getUserInfo() {
      this.detailLoading = true
      consumer_user_signUser()
          .then((result) => {
            const detail = result.datas;
            this.oldForm.phone = detail.phone;
            const certificateForm = this.certificateForm;
            // 中文姓名，生日，英文姓，英文名，性别
            const {
              familyName,
              givenName,
              realName,
              birthday,
              englishSurnames,
              englishName,
              staffSex,
              certificates,
            } = detail;

            let countryCode = '';
            detail.certificates.forEach(value => {
              if (value.countryCode) {
                countryCode = value.countryCode
              }
              if (!value.issCountryCode) {
                  value.issCountryCode = 'CN'
              }
            })

            if (certificates && certificates.length) {
              for (let i = 0; i < certificates.length; i++) {
                const item = certificates[i];
                if (item.def === 1) this.defaultIndex = i;
              };
            }
            certificateForm.familyName = familyName || '';
            certificateForm.givenName = givenName || '';
            certificateForm.realName = realName || "";
            certificateForm.birthday = birthday || "";
            certificateForm.englishSurnames = englishSurnames || "";
            certificateForm.englishName = englishName || "";
            certificateForm.staffSex = staffSex || 1;
            certificateForm.countryCode = countryCode || 'CN';

            if (certificates.length) {
              certificateForm.certificates = [...certificates];
            } else {
              certificateForm.certificates = [
                { certificateType: 1, certificateValue: "" },
              ];
            }

            this.detail = detail;
          }).finally(() => {
            this.detailLoading = false;
      })
    },

    //获取12306登录账号信息
    async getTrainAccountInfo() {
      this.cardLoading = true;
      let res = await consumer_trains_account_myAccount();
      this.cardLoading = false;
      this.trainAccount = res.datas;
    },

    //获取修改12306账号的按钮文字
    getTrainAccountBtnTxt(status) {
      let btnTxt = ''
      switch (status) {
        case 4:
          btnTxt = '去授权12306'
          break;
        default:
          btnTxt = '变更账号'
          break;
      }
      return btnTxt
    },

    //跳转至变更12306账号的页面
    toChangeTrainAccount(status) {
      let routerName = '';
      switch (status) {
        case 2:
          routerName = 'admin-train-account-check';
          break;
        default:
          routerName = 'admin-train-account-depute';
          break;
      }
      this.$router.push({
        name: routerName
      })
    },

    // 弹出修改手机号弹出
    popup_phone_dialog() {
      this.errCodeMsg = '';
      if (!this.oldForm.phone) {
        this.oldShow = false;
        this.phoneDialogTitle = '绑定手机号码';
      } else {
        this.phoneDialogTitle = '修改手机号码';
      }
      this.phoneVisible = true;
    },

    // 关闭修改手机号弹窗
    close_phone_dialog() {
      this.phoneVisible = false;
      this.$refs.oldForm.clearValidate();
      this.$refs.newForm.clearValidate();
      this.oldForm.vcode = "";
      const newForm = this.newForm;
      newForm.phone = "";
      newForm.vcode = "";
      this.oldShow = true;
    },

    // 点击下一步
    commit_old_code() {
      this.$refs.oldForm.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({ text: "请求中..." });
          consumer_sys_sms_vcodeVerify(this.oldForm)
            .then((result) => {
              this.loadingInstance.close();
              if (result.datas) {
                this.oldShow = false;
                this.$refs.newForm.clearValidate();
              } else {
                this.$message.warning(result.msg);
              }
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
    // 确认修改密码
    commit_new_phone() {
      this.errCodeMsg = '';
      this.$refs.newForm.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({text: "请求中..."});
          consumer_user_updatePhone(this.newForm)
              .then((result) => {
                this.loadingInstance.close();
                if (!result.datas) {
                  this.errCodeMsg = result.msg;
                  return;
                }
                this.$message.success("修改手机号码成功");
                this.close_phone_dialog();
                this.init();
              })
              .catch((err) => {
                this.errCodeMsg = err.data.msg;
                return;
              });
        } else {
          return false;
        }
      });
    },

    // 显示修改邮箱弹窗
    popup_email_dialog() {
      this.placeholderEmailTxt = this.detail.email ? '请输入新邮箱' : '请输入需绑定的邮箱'
      this.emailForm.oldEmail = JSON.parse(JSON.stringify(this.detail.email));
      this.emailForm.newEmail = '';
      this.errMailMsg = '';
      this.emailVisible = true;
    },

    // 关闭修改邮箱弹框
    close_email_dialog() {
      this.emailVisible = false;
    },

    //保存新邮箱
    commit_new_email() {
      if (!this.emailForm.newEmail) {
        this.errMailMsg = this.placeholderEmailTxt;
        return;
      }

      const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 定义邮箱格式的正则表达式
      if (!regExp.test(this.emailForm.newEmail)) {
        this.errMailMsg = '新邮箱格式不正确';
        return;
      }

      consumer_user_updateEmail({email: this.emailForm.newEmail}).then(res => {
        if (res.code !== '000000') {
          this.errMailMsg = res.msg;
          return;
        }
        this.$message.success('修改邮箱成功');
        this.detail.email = this.emailForm.newEmail;
        this.emailVisible = false;
      }).catch(err => {
        this.$message.warning(err.data.msg);
        return;
      });
    },

    popup_certificate_dialog() {
      this.certificateVisible = true;
      this.certificateForm.certificates = [...this.detail.certificates]
      console.log('this.certificateForm.certificates', this.certificateForm)
    },

    close_certificate_dialog() {
      this.certificateVisible = false;
    },

    // 身份证失去焦点时
    identity_blur(index) {
      const certificateForm = this.certificateForm;
      const certificateValue =
        certificateForm.certificates[index].certificateValue;
      if (
        certificateForm.birthday ||
        !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(certificateValue)
      )
        return;
      certificateForm.birthday = `${certificateValue.substr(
        6,
        4
      )}-${certificateValue.substr(10, 2)}-${certificateValue.substr(12, 2)}`;
    },

    certificate_input (index) {
      const certificates = this.certificateForm.certificates;
      certificates[index].certificateValue = certificates[index].certificateValue.replace(/[^a-zA-Z0-9\-\_\.]/g, '');
    },

    // 英文名输入时
    change_englishName(key) {
      const certificateForm = this.certificateForm;
      let str = certificateForm[key];
      certificateForm[key] = str.replace(/[^a-zA-Z\_\-\.]/g, "");
    },


    // 删除一项证件
    delet_certificate() {
      const certificates = this.certificateForm.certificates;
      const length = certificates.length;
      certificates.pop();

      if (length === 1 || length - 1 === this.defaultIndex) {
        this.defaultIndex = 0;
      }
    },
    // 添加一项证件
    add_certificate() {
      this.certificateForm.certificates.push({
        certificateType: '',
        certificateValue: "",
        issCountryCode: '',
        passengerExpireDate: '',
      });
      console.log('this.certificateForm', this.certificateForm)
    },

    // 判断是否已被选择
    disabled_certificate (select, current) {
      let selectArr = this.certificateForm.certificates.filter((item) => {if (item.certificateType) return item;}).map((item) => item.certificateType);
      if (selectArr.includes(current) && select !== current) {
        return true;
      } else {
        return false;
      }
    },

    // 提交确认修改时
    commit_certificate() {
      this.$refs.certificateForm.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({ text: "请求中..." });
          const params = {...this.certificateForm};
          if (this.defaultIndex === '' || this.defaultIndex === null || this.defaultIndex === undefined) this.defaultIndex = 0;
          params.realName = params.familyName + params.givenName;
          params.certificates.forEach(value => {
            value.countryCode = params.countryCode
          })
          console.log(this.defaultIndex);
          for (let index = 0; index < params.certificates.length; index++) {
            const item = params.certificates[index];
            console.log(index, this.defaultIndex)
            if (index === this.defaultIndex) {
              item.def = 1;
            } else {
              item.def = 0;
            }
          };

          consumer_user_updateUser(params)
            .then((result) => {
              this.loadingInstance.close();
              this.close_certificate_dialog();
              this.init();
            })
            .catch((err) => {
              this.loadingInstance.close();
            });
        } else {
          return false;
        }
      });
    },
    cardExpireDateDisabledDate(val) {
      const now = moment(val).format('YYYY-MM-DD')
      const nowTime = moment(now + ' 00:00:00').valueOf()
      const today = moment().format('YYYY-MM-DD')
      const todayTime = moment(today + ' 00:00:00').valueOf();
      return nowTime < todayTime
    },
    queryCountryTwoWord() {
      consumer_country_queryCountryTwoWord().then(res => {
        this.countryList = res.datas
      })
    },
    changeCertificateType(index, val) {
      this.$refs.certificateForm.validateField('certificates.' + index + '.certificateType')
    },

    //获取邮箱输入提示
    getEmailTip() {
      return this.emailForm.oldEmail?'新邮箱：':'邮箱：'
    }
  },
  created() {},
  mounted() {
    this.getCertificateType();
    this.queryCountryTwoWord();
  },
  activated() {
    this.init();
  },
  deactivated() {},
  destroyed() {},
  watch: {},
};
