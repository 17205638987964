/*
*查询当前用户信息  http://api-alpha.haolvtrip.com/doc.html#/haolv-biz/t-us-user-controller/getSignUserUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_user_signUser = (data) => {
    if (!data) data = {};
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/user/signUser',
        data
    }
    return __request(pParameter)
}
export default consumer_user_signUser