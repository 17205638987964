// 发送验证码
import consumer_sys_sms_vcodeSend from "@/lib/data-service/haolv-default/consumer_sys_sms_vcodeSend";

export default {
  props: {
    needChangePhone: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String || Number,
    },
    check_token: {
      type: Boolean,
      default: false
    },
    enable_error_alert: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      toBeSend: true,
      numberOfSeconds: 60,
    };
  },
  computed: {
    verifyPhone () {
      return !/^1[3456789]\d{9}$/.test(this.phone);
    },
  },
  methods: {
    init () {
    
    },

    send_auth_code () {
      let params = {
        needChangePhone: this.needChangePhone,
        phone: this.phone,
        sence: 'ALIYUN_VCODE_CHANGE_INFO',
        check_token: this.check_token,
        enable_error_alert: this.enable_error_alert
      }
      consumer_sys_sms_vcodeSend(params).then((result) => {
        if (result.datas.code !== 'OK') {
          this.$message.success(result.datas.message);
          return;
        }
        this.count_down();
      }).catch((err) => {
        this.$message.error(err.data.msg)
      });
    },

    count_down () {
      this.toBeSend = false;
      let timer = setInterval(() => {
        if (this.numberOfSeconds <= 0) {
          clearInterval(timer);
          this.numberOfSeconds = 60;
          this.toBeSend = true;
        }
        this.numberOfSeconds -= 1;
      }, 1000);
    }
  }
}